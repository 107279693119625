<template>
  <div style="color: white; margin-top: 30px;">
  <h1>Asset and Model Data Checker</h1>
    <div
      v-for="model, index in modelData"
      :key="index"
      class="model-container"
    >
      <h3 style="margin-bottom: 25px; text-align: center;">{{ model.yearMakeModel }}</h3>
      <img :src="model.performanceImageUrl">
      <img :src="model.safetyImageUrl">
      <img :src="model.technologyImageUrl">
      <img :src="model.jellybeanUrl">
      <img :src="model.overviewImageUrl">
      <img :src="model.panelOverviewImageUrl" style="max-width: 50%;">
      <img :src="model.panelPerformanceImageUrl" style="max-width: 50%;">
      <img :src="model.panelSafetyImageUrl" style="max-width: 50%;">
      <img :src="model.panelTechnologyImageUrl" style="max-width: 50%;">
      <table>
        <tr
          v-for="value, key in model"
          :key="key"
          style="font-size: 12px; padding: 4px;"
        >
          <td align="right" style="padding-right: 10px;">{{ key }}:</td>
          <td>{{ value }}</td>
        </tr>
      </table>      
    </div>
  </div>
</template>

<script>

export default {
  name: 'Asset Explorer',

  components: {
    
  },

  created() {
    document.title = 'Gravity Asset Explorer';
    this.getModels();
  },

  data() {
    return {
      rawModelData: '',
      modelData: '',
    };
  },

  methods: {
    getModels() {
      var self = this;
      this.axios
        .get('https://stellantis.akamaized.net/images/program/lj28u/ark/model_aux_data_working.csv')
        .then(response => {
          self.modelData = self.convertCsv(response.data);
        })
        .catch(function() {
          alert('Did not find model_aux_data.csv');
      });
    },

    convertCsv(str) {
      var arr = [];
      var quote = false;  // 'true' means we're inside a quoted field

      // Iterate over each character, keep track of current row and column (of the returned array)
      for (var row = 0, col = 0, c = 0; c < str.length; c++) {
        var cc = str[c], nc = str[c+1];        // Current character, next character
        arr[row] = arr[row] || [];             // Create a new row if necessary
        arr[row][col] = arr[row][col] || '';   // Create a new column (start with empty string) if necessary

        // If the current character is a quotation mark, and we're inside a
        // quoted field, and the next character is also a quotation mark,
        // add a quotation mark to the current column and skip the next character
        if (cc == '"' && quote && nc == '"') { arr[row][col] += cc; ++c; continue; }

        // If it's just one quotation mark, begin/end quoted field
        if (cc == '"') { quote = !quote; continue; }

        // If it's a comma and we're not in a quoted field, move on to the next column
        if (cc == ',' && !quote) { ++col; continue; }

        // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
        // and move on to the next row and move to column 0 of that new row
        if (cc == '\r' && nc == '\n' && !quote) { ++row; col = 0; ++c; continue; }

        // If it's a newline (LF or CR) and we're not in a quoted field,
        // move on to the next row and move to column 0 of that new row
        if (cc == '\n' && !quote) { ++row; col = 0; continue; }
        if (cc == '\r' && !quote) { ++row; col = 0; continue; }

        // Otherwise, append the current character to the current column
        arr[row][col] += cc;
      }

      let columns = arr[0];
      let rows = arr.splice(1);
      let result = [];

      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        result.push(row.reduce(function(tempResult, field, index) {
          tempResult[columns[index]] = field;
          return tempResult;
        }, {}));
      }

      return result;

    },
    
  },

  computed: {

  },

};
</script>

<style scoped>

  .model-container {
    width: 100%;
    padding: 30px;
    border: 1px solid white;
    border-radius: 10px;
    margin-bottom: 30px;
    box-sizing: border-box;
  }

  .model-container img {
    max-width: 100%;    
  }

</style>